import { lazy } from 'react';

import Loadable from 'Components/Loadable';
import CVSubmitLayout from 'layout/CVSubmitLayout/index';

const CVSubmitForm = Loadable(lazy(() => import('Pages/WhiteCollar/CVSubmitForm')));

const CVSubmitRoutes = {
    path: '/placement/',
    element: <CVSubmitLayout />,
    children: [{
        path: 'applynow',
        element: <CVSubmitForm />,
    }]
};
export default CVSubmitRoutes;
