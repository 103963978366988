import { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';

// material-ui
// import { useTheme } from '@mui/material/styles';
// import { Box } from '@mui/material';

// project import
import { SideBar2 } from "./SideBar";
import Header from './Header';
import { getUserProfileData } from 'ApiCalls/User';
import RefreshData from 'Processs/RefreshData';

// ==============================|| MAIN LAYOUT ||============================== //

const MainLayout = () => {
  // const theme = useTheme();
  // const matchDownLG = useMediaQuery(theme.breakpoints.down('lg'));

  const [userId, setUserId] = useState('');



  
  useEffect(() => {
    const refreshPublicData = async () => {
      await RefreshData();
    };
    
    const getProfileData = async () => {
      try {
        const response = await getUserProfileData();
        if (Array.isArray(response.data) && response.data.length > 0) {
          localStorage.setItem("user_phone", "+" + response.data[0].username);
          localStorage.setItem("profile_photo", response.data[0].profile_photo);
          localStorage.setItem("user_name", response.data[0].full_name);
        }
      } catch (error) {
        console.error(error);
      }
    };
    refreshPublicData();
    if (localStorage.getItem('userId')) {
      setUserId(localStorage.getItem('userId'));
      getProfileData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="App" style={{ overflowY: "hidden" }}>
      <Header userId={userId} />
      <div
          style={{
            display: "flex",
            height: "100vh",
            overflowY: "hidden",
            overflowX: "auto",
          }}
        >
          <SideBar2 />
          <Outlet />
        </div>

    </div>
    // <Box sx={{ display: 'flex', width: '100%' }}>
    //   <Header userId={userId} />
    //   <SideBar2 />
    //   <Box
    //     component="main"
    //     sx={{
    //       width: '100%',
    //       flexGrow: 1,
    //       p: { xs: 2, sm: 3 },
    //       backgroundColor: theme.palette.background.default
    //     }}
    //   >
        
    //     <Outlet />
    //   </Box>
    // </Box>
  );
};

export default MainLayout;
