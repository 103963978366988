import React, { useEffect, useState } from "react";
import { Sidebar, Menu, MenuItem } from "react-pro-sidebar";
import { getSideBarItems } from "ApiCalls/PublicData";
import { Link, NavLink } from "react-router-dom";
import side_bar_back from "img/side_bar_back.jpg";
import user from "img/ic_gender_male.png";
import { useLocation } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeftLong,
  faArrowRightLong,
} from "@fortawesome/free-solid-svg-icons";
import { Tooltip } from "@mui/material";

import {
  CDBSidebar,
  CDBSidebarContent,
  CDBSidebarHeader,
  CDBSidebarMenu,
  CDBSidebarMenuItem,
} from "cdbreact";

const Item2 = ({ item, selected, setSelected }) => {
  return (
    <NavLink
      to={item.link_type==="browserLink"?item.link:"/" + item.link}
      onClick={() => {
        setSelected(item.id);
      }}
    >
      <Tooltip title={item.title}>
        <CDBSidebarMenuItem
          iconSize="lg"
          className={selected === item.id ? "sidebar_selected_item" : ""}
          icon={item.sidebar_icon}
        >
          <div
            // style={{ color: selected === item.id ? "#FFFFFF" : "" }}
            className={
              selected === item.id
                ? "sidebar_item_font_selected"
                : "sidebar_item_font"
            }
          >
            {item.title}
          </div>
        </CDBSidebarMenuItem>
      </Tooltip>
    </NavLink>
  );
};
const Item = ({ item, selected, setSelected, isCollapsed }) => {
  return (
    <MenuItem
      active={selected === item.id}
      className="d-flex mb-4 mx-2 align-items-center"
      key={item.id}
      onClick={() => {
        setSelected(item.id);
        console.log(item.id);
      }}
    >
      <Tooltip title={item.title}>
        <div>
          <Link to={"/" + item.link} style={{ textDecoration: "none" }}>
            <img
              src={item.icon}
              alt={item.title}
              className="icon me-3"
              width="33"
              height="33"
            />
            {isCollapsed && <span>{item.title}</span>}
          </Link>
        </div>
      </Tooltip>
    </MenuItem>
  );
};

const SideBar2 = () => {
  const location = useLocation();
  const [sideBarItems, setSideBarItems] = useState([]);
  const [selected, setSelected] = useState(0);
  const [collapsed, setCollapsed] = useState(false);
  const [image, setImage] = useState("");

  useEffect(() => {
    const getItems = async () => {
      const response = await getSideBarItems();
      if (response.status === 200) {
        setSideBarItems([
          {
            id: 0,
            title: "হোম",
            icon: "https://iuazegsorvopdfkveycu.supabase.co/storage/v1/object/public/shomvob-public/feature_cart/home_icon.png",
            sidebar_icon: "home",
            display_sorting_id: 0,
            link: "",
            link_type: "internal",
          },
          {
            id: 1,
            title: "প্রোফাইল",
            icon: { user },
            sidebar_icon: "user",
            display_sorting_id: 1,
            link: "profile",
            link_type: "internal",
          },
        ]);
        setSideBarItems((prevItems) => [
          ...prevItems,
          ...response.data
        ]);
      }
    };

    const pathname = location.pathname.toLowerCase();
    const linkKeywords = {
      company: 32,
      home: 0,
      profile: 1,
      trainings: 30,
      youtube_videos: 30,
      cvmaker: 33,
      application_tracker: 31,
      saved_jobs_list: 34,
      success: 35,
      job_description: 32,
      jobapplication: 32,
      govtjobs: 58,
    };
    const selectedItem = Object.keys(linkKeywords).find((key) =>
      pathname.includes(key)
    );
    const selectedIndex = selectedItem ? linkKeywords[selectedItem] : 0;

    setSelected(selectedIndex);
    getItems();
  }, []);

 

  return (
    <div className="sidebar-responsive">
      <CDBSidebar
        toggled={collapsed}
        textColor="#707070"
        backgroundColor="#fff"
      >
        <CDBSidebarHeader
          className="align-items-center"
          prefix={
            <div
              style={{ minHeight: "44px" }}
              className="d-flex align-items-center"
            >
              <i className="fa fa-bars" />
            </div>
          }
        >
          <div
            className="container"
            style={{ display: "flex", alignItems: "center" }}
          >
            <img
              src={
                localStorage.getItem("userId")
                  ? localStorage.getItem("profile_photo")
                  : user
              }
              alt=""
              style={{ width: "45px" }}
            />
            <div className="mx-2">
              {localStorage.getItem("userId") ? (
                <a
                  className="sidebar_header_text"
                  href="/Profile"
                  style={{
                    textDecoration: "none",
                  }}
                >
                  {localStorage.getItem("user_name")}
                </a>
              ) : (
                <a
                  className="sidebar_header_text"
                  href="/SignUp"
                  style={{
                    textDecoration: "none",
                  }}
                >
                  {"সাইন ইন | রেজিস্ট্রেশন করুন"}
                </a>
              )}
            </div>
          </div>
        </CDBSidebarHeader>

        <CDBSidebarContent className="sidebar-content">
          <CDBSidebarMenu>
            {sideBarItems.map((item) => (
              <Item2
                item={item}
                selected={selected}
                setSelected={setSelected}
                isCollapsed={!collapsed}
              />
            ))}
          </CDBSidebarMenu>
          {localStorage.getItem("userId") && (
            <>
              <hr />
              <CDBSidebarMenu>
                <CDBSidebarMenuItem iconSize="lg" icon="sign-out" onClick={() => {
                  localStorage.removeItem("userId");
                  localStorage.removeItem("accessToken");
                  localStorage.removeItem("refreshToken");
                  localStorage.removeItem("expireTime");
                  localStorage.removeItem("user_name");
                  localStorage.removeItem("user_phone");
                  localStorage.removeItem("profile_photo");
                  window.location = "/";

                }}>
                  Log Out
                </CDBSidebarMenuItem>
              </CDBSidebarMenu>
            </>
          )}
        </CDBSidebarContent>
      </CDBSidebar>
    </div>
  );
};

const SideBar = () => {
  const [sideBarItems, setSideBarItems] = useState([]);
  const [selected, setSelected] = useState(1);
  const [collapsed, setCollapsed] = useState(false);
  const [image, setImage] = useState("");

  useEffect(() => {
    const getItems = async () => {
      const response = await getSideBarItems();
      if (response.status === 200) {
        setSideBarItems([
          {
            id: 1,
            title: "হোম",
            icon: "https://iuazegsorvopdfkveycu.supabase.co/storage/v1/object/public/shomvob-public/feature_cart/home_icon.png",
            display_sorting_id: 1,
            link: "",
          },
        ]);
        setSideBarItems((prevItems) => [...prevItems, ...response.data]);
      }
    };
    getItems();
  }, []);

  const toggleSidebar = () => {
    setCollapsed(!collapsed);
  };

  return (
    <div>
      <Sidebar
        collapsed={collapsed}
        image={side_bar_back}
        style={{
          backgroundImage: `url(${side_bar_back})`,
          backgroundSize: "100% auto",
          backgroundRepeat: "repeat-y",
          minHeight: "100vh",
          overflowX: "hidden",
        }}
      >
        <div
          key={0}
          className="justify-content-center"
          style={{ marginTop: "22px" }}
        >
          <div className="avatar d-flex justify-content-center">
            <img
              className="avatar-img rounded-circle shadow"
              src={
                localStorage.getItem("userId")
                  ? localStorage.getItem("profile_photo")
                  : user
              }
              alt="avatar"
              style={{
                width: "64px",
                height: "64px",
                objectFit: "cover",
              }}
            />
          </div>
          {!collapsed && (
            <div
              className="d-flex justify-content-center"
              style={{ marginTop: "11px" }}
            >
              {localStorage.getItem("userId") ? (
                <a
                  className="sidebar_header_text"
                  href="/Profile"
                  style={{
                    textDecoration: "none",
                  }}
                >
                  {localStorage.getItem("user_name") +
                    " | " +
                    localStorage.getItem("user_phone")}
                </a>
              ) : (
                <a
                  className="sidebar_header_text"
                  href="/SignUp"
                  style={{
                    textDecoration: "none",
                  }}
                >
                  {"সাইন ইন | রেজিস্ট্রেশন করুন"}
                </a>
              )}
            </div>
          )}
        </div>
        <Menu style={{ marginTop: "36px" }}>
          {sideBarItems.map((item) => (
            <Item
              item={item}
              selected={selected}
              setSelected={setSelected}
              isCollapsed={!collapsed}
            />
          ))}

          <MenuItem
            className="d-flex mb-4 mx-2 align-items-center"
            onClick={toggleSidebar}
            key={-1}
          >
            <FontAwesomeIcon
              icon={collapsed ? faArrowRightLong : faArrowLeftLong}
              style={{ marginLeft: "10px" }}
            />
          </MenuItem>
        </Menu>
      </Sidebar>
    </div>
  );
};

export default SideBar;
export { SideBar2 };
