import React, { useEffect, useState } from "react";
import { Nav, NavLink, Navbar, Offcanvas } from "react-bootstrap";
import shomvob_logo from "img/shomvob_logo.png";
import header_banner from "img/header_banner.png";
import play_store from "img/play_store.png";
import user from "img/ic_gender_male.png";
import { CDBSidebarMenuItem } from "cdbreact";
import { useLocation } from "react-router";
import { getSideBarItems } from "ApiCalls/PublicData";

const Item2 = ({ item, selected, setSelected }) => {
  return (
    <Nav.Link
      href={"/" + item.link}
      onClick={() => {
        setSelected(item.id);
      }}
    >
      <CDBSidebarMenuItem
        iconSize="lg"
        className={selected === item.id ? "sidebar_selected_item" : ""}
        icon={item.sidebar_icon}
      >
        <div
          
          className={
            selected === item.id
              ? "sidebar_item_font_selected"
              : "sidebar_item_font"
          }
        >
          {item.title}
        </div>
      </CDBSidebarMenuItem>
    </Nav.Link>
    // <Nav.Link href="#action1">Home</Nav.Link>
  );
};

const Header = () => {
  const location = useLocation();
  const [sideBarItems, setSideBarItems] = useState([]);
  const [selected, setSelected] = useState(0);
  const [maxHeight, setMaxHeight] = useState(window.innerHeight);
  useEffect(() => {
    const getItems = async () => {
      const response = await getSideBarItems();
      if (response.status === 200) {
        setSideBarItems([
          {
            id: 0,
            title: "হোম",
            icon: "https://iuazegsorvopdfkveycu.supabase.co/storage/v1/object/public/shomvob-public/feature_cart/home_icon.png",
            sidebar_icon: "home",
            display_sorting_id: 0,
            link: "",
          },
          {
            id: 1,
            title: "প্রোফাইল",
            icon: { user },
            sidebar_icon: "user",
            display_sorting_id: 1,
            link: "profile",
          },
        ]);
        setSideBarItems((prevItems) => [...prevItems, ...response.data]);
      }
    };

    const pathname = location.pathname.toLowerCase();
    const linkKeywords = {
      company: 32,
      home: 0,
      profile: 1,
      trainings: 30,
      youtube_videos: 30,
      cvmaker: 33,
      application_tracker: 31,
      saved_jobs_list: 34,
      success: 35,
      job_description: 32,
      jobapplication: 32,
      govtjobs: 58,
    };
    const selectedItem = Object.keys(linkKeywords).find((key) =>
      pathname.includes(key)
    );
    const selectedIndex = selectedItem ? linkKeywords[selectedItem] : 0;

    setSelected(selectedIndex);
    getItems();
  }, []);
  useEffect(() => {
    const handleResize = () => {
      setMaxHeight(window.innerHeight);
    };

    // Listen for window resize events
    window.addEventListener("resize", handleResize);

    // Remove the event listener on component unmount
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return (
    <Navbar key={false} expand={false} bg="white" variant="light">
      <div className="container-fluid ">
        <Navbar.Brand href="/">
          <img alt="" src={shomvob_logo} style={{ maxHeight: "50px" }} />
        </Navbar.Brand>
        <Nav className="me-auto sidebar-responsive">
          <img
            alt=""
            src={header_banner}
            className="img-fluid"
            style={{ maxHeight: "79px" }}
          />
        </Nav>
        <Nav className="justify-content-end sidebar-responsive">
          <Nav.Link
            href="https://play.google.com/store/apps/details?id=com.shomvob.app"
            target="_blank"
          >
            <img alt="" src={play_store} style={{ maxHeight: "46px" }} />
          </Nav.Link>
        </Nav>
        <Navbar.Toggle
          aria-controls={`offcanvasNavbar-expand-false`}
          className="header-responsive"
        />
        <Navbar.Offcanvas
          id={`offcanvasNavbar-expand-false`}
          className="header-responsive "
          aria-labelledby={`offcanvasNavbarLabel-expand-false`}
          placement="end"
        >
          <Offcanvas.Header closeButton>
            <Offcanvas.Title id={`offcanvasNavbarLabel-expand-false`}>
              <div
                className="container mt-3"
                style={{ display: "flex", alignItems: "center" }}
              >
                <img
                  src={
                    localStorage.getItem("userId")
                      ? localStorage.getItem("profile_photo")
                      : user
                  }
                  alt=""
                  style={{ width: "45px" }}
                />
                <div className="mx-2">
                  {localStorage.getItem("userId") ? (
                    <a
                      className="sidebar_header_text"
                      href="/Profile"
                      style={{
                        textDecoration: "none",
                      }}
                    >
                      {localStorage.getItem("user_name")}
                    </a>
                  ) : (
                    <a
                      className="sidebar_header_text"
                      href="/SignUp"
                      style={{
                        textDecoration: "none",
                      }}
                    >
                      {"সাইন ইন | রেজিস্ট্রেশন করুন"}
                    </a>
                  )}
                </div>
              </div>
            </Offcanvas.Title>
          </Offcanvas.Header>
          <hr />
          <Offcanvas.Body>
            <Nav className="justify-content-end  pe-3">
              {sideBarItems.map((item) => (
                <Item2
                  item={item}
                  selected={selected}
                  setSelected={setSelected}
                />
              ))}
            </Nav>
            {localStorage.getItem("userId") && (
              <>
                <hr />
                <Nav className="justify-content-end flex-grow-1 pe-3">
                  <CDBSidebarMenuItem
                    iconSize="lg"
                    icon="sign-out"
                    onClick={() => {
                      localStorage.removeItem("userId");
                      localStorage.removeItem("accessToken");
                      localStorage.removeItem("refreshToken");
                      localStorage.removeItem("expireTime");
                      localStorage.removeItem("user_name");
                      localStorage.removeItem("user_phone");
                      localStorage.removeItem("profile_photo");
                      window.location = "/";
                    }}
                  >
                    <div className={"sidebar_item_font"}>Log Out</div>
                  </CDBSidebarMenuItem>
                </Nav>
              </>
            )}
          </Offcanvas.Body>
        </Navbar.Offcanvas>
      </div>
    </Navbar>
  );
};

export default Header;
