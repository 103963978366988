// material-ui
import { createTheme } from '@mui/material/styles';

// third-party
import { presetPalettes } from '@ant-design/colors';

// project import
import ThemeOption from './theme';

// ==============================|| DEFAULT THEME - PALETTE  ||============================== //

const Palette = (mode) => {
  const colors = presetPalettes;

  const greyPrimary = [
    '#ffffff',
    '#fafafa',
    '#f5f5f5',
    '#f0f0f0',
    '#d9d9d9',
    '#bfbfbf',
    '#8c8c8c',
    '#595959',
    '#262626',
    '#141414',
    '#000000'
  ];
  const greyAscent = ['#fafafa', '#bfbfbf', '#434343', '#1f1f1f'];
  const greyConstant = ['#fafafb', '#e6ebf1'];

  colors.grey = [...greyPrimary, ...greyAscent, ...greyConstant];

  const paletteColor = ThemeOption(colors);

  return createTheme({
    palette: {
      mode,
      common: {
        black: '#0F2934',
        white: '#F8F8F8'
      },
      ...paletteColor,
      text: {
        primary: paletteColor.grey[700],
        secondary: paletteColor.grey[500],
        disabled: paletteColor.grey[400]
      },
      action: {
        disabled: paletteColor.grey[300]
      },
      divider: paletteColor.grey[200],
      background: {
        paper: paletteColor.grey[0],
        default: '#F4F7FB'
      },
      customColor: {
        tertiary1: '#EFB80D',
        tertiary2: '#8BC940',
        inactiveCardBorder: '#FF0000',
        alert: '#F00',
        table_heading: '#E5F3FF',
        primary: '#0378E3',
        gray1: '#D7D7D7',
        gray: '#828282',
        gray2: '#5D5C5C',
        black: '#0F2934',
        defultCardBackground: '#FFF',
        shortListButton: '#079F56',
        Secondary: '#27ACE3',
        textAreaBack: '#EDF7FF',
        qnaNoteBackground: '#FFFCEE',
        addQnaBtnBackground: '#F4F4F5',
        qnaCardBackground1: '#F6BC0E'
      }
    }
  });
};
// #F4F7FB
export default Palette;
