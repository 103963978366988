import { lazy } from "react";

// project import
import Loadable from "Components/Loadable";
import MainLayout from "layout/MainLayout";

import Protected from "./Protected";
import Protected2 from "./Protected2";

// render - Pages
const Trainings = Loadable(lazy(() => import("Pages/YoutubVideos")));
const YoutubVideos = Loadable(lazy(() => import("Pages/YoutubVideos")));
const SuccessStorys = Loadable(lazy(() => import("Pages/SuccessStorys")));
const SavedJobs = Loadable(lazy(() => import("Pages/SavedJobs")));
const AppliedJobs = Loadable(lazy(() => import("Pages/AppliedJobs")));
const JobApplication1 = Loadable(
  lazy(() => import("Pages/JobApplication1"))
);
const ApplicationTracker = Loadable(
  lazy(() => import("Pages/ApplicationTracker"))
);
const AllCompany = Loadable(lazy(() => import("Pages/Company/AllCompany")));
const SingleCompanyProfile = Loadable(
  lazy(() => import("Pages/Company/SingleCompanyDescription"))
);
const AllGovtJobs = Loadable(
  lazy(() => import("Pages/GovtJob/AllGovtJobs"))
);
const SingleGovtJob = Loadable(
  lazy(() => import("Pages/GovtJob/SingleGovtJob"))
);
const Home = Loadable(lazy(() => import("Pages/Home")));
const NotFound = Loadable(lazy(() => import("NotFound")));
const JobDescription = Loadable(lazy(() => import("Pages/JobDescription")));
const SignUp = Loadable(lazy(() => import("Pages/User/SignUp")));
const SignOut = Loadable(lazy(() => import("SignOut")));
const NewUserInformation = Loadable(
  lazy(() => import("NewUserInformation"))
);
const CVMaker = Loadable(lazy(() => import("Pages/User/CVMaker")));
const Redirect = Loadable(lazy(() => import("Redirect")));
const CreateProfile = Loadable(
  lazy(() => import("Pages/User/CreateProfile"))
);
const Profile = Loadable(lazy(() => import("Pages/User/Profile")));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
  path: "/",
  element: <MainLayout />,
  children: [
    {
      path: "/",
      element: <Home />,
    },
    {
      path: "/Redirect",
      element: <Redirect />,
    },
    {
      path: "/CreateProfile",
      element: <CreateProfile />,
    },
    {
      path: "/job_list",
      element: <Home />,
    },
    {
      path: "/Trainings",
      element: <Trainings />,
    },
    {
      path: "/all_trainings",
      element: <Trainings />,
    },
    {
      path: "/youtube_videos",
      element: <YoutubVideos />,
    },
    {
      path: "/SuccessStorys",
      element: <SuccessStorys />,
    },
    {
      path: "/success_stories_list",
      element: <SuccessStorys />,
    },
    {
      path: "/govtjobs",
      element: <AllGovtJobs />,
    },
    {
      path: "/govtjobs/single_govt_job",
      element: <SingleGovtJob />,
    },
    {
      path: "/application_tracker",
      element: (
        <Protected>
          <AppliedJobs />
        </Protected>
      ),
    },
    {
      path: "/single_application_tracker",
      element: (
        <Protected>
          <ApplicationTracker />
        </Protected>
      ),
    },
    {
      path: "/single_job_description",
      element: <JobDescription />,
    },
    {
      path: "/single_company_description",
      element: <SingleCompanyProfile />,
    },
    {
      path: "/SignOut",
      element: <SignOut />,
    },
    {
      path: "/CVMaker",
      element: (
        <Protected>
          <CVMaker />
        </Protected>
      ),
    },
    {
      path: "/saved_jobs_list",
      element: (
        <Protected>
          <SavedJobs />
        </Protected>
      ),
    },
    {
      path: "/Profile",
      element: (
        <Protected>
          <Profile />
        </Protected>
      ),
    },
    {
      path: "/JobApplication1",
      element: (
        <Protected>
          <JobApplication1 />
        </Protected>
      ),
    },
    {
      path: "/company_profiles",
      element: <AllCompany />,
    },
    {
      path: "/NewUserInformation",
      element: <NewUserInformation />,
    },
    {
      path: "*",
      element: <NotFound />,
    },
    {
      path: "SignUp",
      element: (
        <Protected2>
          <SignUp />
        </Protected2>
      ),
    },
  ],
};

export default MainRoutes;
