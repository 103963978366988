import { Outlet } from 'react-router-dom';
import { Box } from '@mui/material';


// ==============================|| MAIN LAYOUT ||============================== //

const CVSubmitLayout = () => {
  return (
    <Box sx={{ width: '100%' }}>
      <Box component="main" sx={{ width: '100%', flexGrow: 1 }}>
        <Outlet />
      </Box>
    </Box>
  );
};

export default CVSubmitLayout;
