
import { refreshAccessToken } from "ApiCalls/User";
import { getPublicData } from "../ApiCalls/PublicData";

const RefreshData = async () => {
  const userId = localStorage.getItem("userId");
  const currentTime = Date.now();
  if (
    !localStorage.getItem("publicTableData") ||
    !localStorage.getItem("public_data_expiretime") ||
    currentTime > localStorage.getItem("public_data_expiretime")
  ) {
    const pbData = await getPublicData();
    if (pbData?.status === 200) {
      localStorage.setItem("publicTableData", JSON.stringify(pbData.data[0]));
      localStorage.setItem("public_data_expiretime", currentTime + 3600);
    }
  }
  if (
    userId &&
    localStorage.getItem("refreshToken") &&
    currentTime > localStorage.getItem("expireTime")
  ) {
    const response = await refreshAccessToken();

    if (response?.status === 200) {
      localStorage.setItem("accessToken", response.data.access_token);
      localStorage.setItem("refreshToken", response.data.refresh_token);
      const currentTime = Date.now();
      localStorage.setItem(
        "expireTime",
        currentTime + response.data.expires_in * 1000
      );
    } else {
      localStorage.removeItem("userId");
      localStorage.removeItem("accessToken");
      localStorage.removeItem("refreshToken");
      localStorage.removeItem("expireTime");
      window.location = "/SignIn";
    }
  } else if (
    !localStorage.getItem("refreshToken") &&
    localStorage.getItem("userId")
  ) {
    localStorage.removeItem("userId");
    localStorage.removeItem("accessToken");
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("expireTime");
    window.location = "/SignIn";
  }

  
  
};

export default RefreshData;
