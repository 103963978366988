const Typography = () => ({
  htmlFontSize: 16,
  fontFamily: 'Lato, sans-serif',
  fontWeightLight: 300,
  fontWeightRegular: 400,
  fontWeightMedium: 500,
  fontWeightBold: 600,
  h1: {
    fontWeight: 600,
    fontSize: '3.000rem',
    lineHeight: 1.5
  },
  h2: {
    fontWeight: 600,
    fontSize: '2.500rem',
    lineHeight: 1.5
  },
  h3: {
    fontWeight: 600,
    fontSize: '2.000rem',
    lineHeight: 1.33
  },
  h4: {
    fontWeight: 600,
    fontSize: '1.750rem',
    lineHeight: 1.8
  },
  module_title: {
    fontWeight: 600,
    fontSize: '1.750rem',
    lineHeight: 1.4
  },
  h5: {
    fontWeight: 600,
    fontSize: '1.438rem',
    lineHeight: 1.5
  },
  h6: {
    fontWeight: 400,
    fontSize: '1.125rem',
    lineHeight: 1.5
  },
  caption: {
    fontWeight: 400,
    fontSize: '0.75rem',
    lineHeight: 1.66
  },
  table_heading: {
    fontWeight: 400,
    fontSize: '1.25rem',
    lineHeight: 1.4
  },
  body: {
    fontWeight: 400,
    fontSize: '1rem',
    lineHeight: 1.2
  },
  paragraph: {
    fontWeight: 400,
    fontSize: '0.812rem',
    lineHeight: 1.66
  },
  extra_small: {
    fontWeight: 400,
    fontSize: '0.688rem',
    lineHeight: 1.66
  },
  body1: {
    fontSize: '0.875rem',
    lineHeight: 1
  },
  body2: {
    fontSize: '0.75rem',
    lineHeight: 1.66
  },
  subtitle1: {
    fontSize: '0.875rem',
    fontWeight: 600,
    lineHeight: 1.57
  },
  subtitle2: {
    fontSize: '0.75rem',
    fontWeight: 500,
    lineHeight: 1.66
  },
  overline: {
    lineHeight: 1.66
  },
  button: {
    textTransform: 'capitalize'
  },
  sidebar: {
    fontSize: '1.25rem',
    fontWeight: '400',
    lineHeight: '34px'
  },
  job_card_title: {
    fontSize: '1.375rem',
    fontWeight: '600',
    lineHeight: '26.4px'
  },
  job_card_body: {
    fontSize: '1.125rem',
    fontWeight: '400',
    lineHeight: '26.4px'
  },
  jobCardTitle: {
    fontSize: '24px',
    fontWeight: '600',
    lineHeight: '29px',
    // fontFamily: "'Lato', serif",
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  }
});

export default Typography;
