import { useRoutes } from 'react-router-dom';

// project import

import MainRoutes from './MainRoutes';
import CVSubmitRoutes from './CVSubmitRoutes';


// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes() {
  return useRoutes([MainRoutes, CVSubmitRoutes]);
}
